"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useId = exports.useLabel = exports.reorderArray = void 0;
var react_ui_1 = require("@flos/react-ui");
var React = __importStar(require("react"));
var uuid_1 = require("uuid");
/**
 *
 * @param array array that you want to rearray
 * @param from index of item which you want to move
 * @param to where you want to place the item
 */
var reorderArray = function (array, from, to) {
    if (from === to) {
        return array.slice();
    }
    var result = array.slice();
    var removed = result.splice(from, 1)[0];
    result.splice(to, 0, removed);
    return result;
};
exports.reorderArray = reorderArray;
var useLabel = function () { return React.useContext(react_ui_1.ResourceContext); };
exports.useLabel = useLabel;
var useId = function (providedId) {
    var idRef = React.useRef(providedId);
    if (!idRef.current) {
        idRef.current = (0, uuid_1.v4)();
    }
    return idRef.current;
};
exports.useId = useId;
