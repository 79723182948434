"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HighwayConfigOptionForm = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var react_ui_1 = require("@flos/react-ui");
var React = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var uuid_1 = require("uuid");
var lib_1 = require("../lib");
var highway_selectors_1 = require("../store/highway.selectors");
var highway_config_option_category_select_1 = require("./highway-config-option-category-select");
var highway_config_option_email_select_1 = require("./highway-config-option-email-select");
var highway_config_option_recipient_select_1 = require("./highway-config-option-recipient-select");
var highway_config_option_target_select_1 = require("./highway-config-option-target-select");
var HighwayConfigOptionFormView = function (props) {
    var getLabel = (0, lib_1.useLabel)();
    var id = (0, lib_1.useId)(props.currentOption && props.currentOption.id);
    var _a = React.useReducer(formSlice.reducer, props.currentOption || __assign(__assign({}, DEFAULT_STATE), { id: id })), values = _a[0], dispatch = _a[1];
    React.useEffect(function () {
        if (props.currentOption) {
            dispatch(formActions.sync(props.currentOption));
        }
    }, [props.currentOption]);
    var setFormFieldValue = function (key, value) {
        dispatch(formActions.setStringValue({
            key: key,
            value: value,
        }));
    };
    return (React.createElement("form", { onSubmit: function (ev) {
            ev.preventDefault();
            props.onSubmit(values);
            dispatch(formActions.reset());
        } },
        React.createElement(react_ui_1.TextField, { label: getLabel('Option Label'), value: values.label, onChange: function (event) {
                var value = event.target.value;
                setFormFieldValue('label', value);
            }, required: true, autoFocus: !props.currentOption }),
        React.createElement(react_ui_1.TextField, { label: getLabel('Option Description'), value: values.infoText, onChange: function (event) {
                var value = event.target.value;
                setFormFieldValue('infoText', value);
            } }),
        !props.isMultipleOption &&
            (!values.isContainer || (0, highway_selectors_1.computeOptionChildrenCount)(values) === 0 ? (React.createElement(react_ui_1.CheckboxField, { checked: values.isContainer, onChange: function () { return dispatch(formActions.changeIsContainer()); }, value: "isContainer", label: getLabel('Option isContainer') })) : (React.createElement("p", null, "Has more selection."))),
        !values.isContainer && (React.createElement(React.Fragment, null,
            React.createElement(react_ui_1.SelectField, { value: values.pathType, label: getLabel('Option Link Type'), onChange: function (event) {
                    var pathType = event.target.value;
                    dispatch(formActions.changePathType(pathType));
                } },
                React.createElement("option", { value: "form" }, "FormIO Form"),
                React.createElement("option", { value: "link" }, "URL")),
            values.pathType === 'form' ? (React.createElement(React.Fragment, null,
                React.createElement(highway_config_option_target_select_1.HighwayConfigOptionTargetSelect, { onSelect: function (value) {
                        setFormFieldValue('targetPath', value);
                    }, onRemove: function () {
                        setFormFieldValue('targetPath', '');
                    }, selectedFormPath: values.targetPath, required: true }),
                React.createElement(highway_config_option_category_select_1.HighwayConfigOptionCategorySelect, { onSelect: function (option) { return dispatch(formActions.addCategory(option)); }, onRemove: function (optionId) {
                        return dispatch(formActions.removeCategory(optionId));
                    }, selectedCategories: values.categories }),
                React.createElement(highway_config_option_recipient_select_1.HighwayConfigOptionRecipientSelect, { onSelect: function (recipientId) {
                        return dispatch(formActions.addRecipient(recipientId));
                    }, onRemove: function (recipientId) {
                        return dispatch(formActions.removeRecipient(recipientId));
                    }, selectedRecipientIds: values.recipientIds }),
                React.createElement(highway_config_option_email_select_1.HighwayConfigOptionEmailSelect, { required: true, onSelect: function (value) {
                        return dispatch(formActions.setStringValue({
                            key: 'emailId',
                            value: value,
                        }));
                    }, onRemove: function () {
                        return dispatch(formActions.setStringValue({
                            key: 'emailId',
                            value: '',
                        }));
                    }, value: values.emailId }))) : (React.createElement(React.Fragment, null,
                React.createElement(react_ui_1.TooltipIcon, null, getLabel('Option Target Help Text')),
                React.createElement(react_ui_1.TextField, { value: values.targetPath, onChange: function (event) {
                        var value = event.target.value;
                        setFormFieldValue('targetPath', value);
                    }, label: getLabel('Option Target'), pattern: "^(/|^https?://)[a-zA-Z0-9-\\._\\/]*$", required: true }))))),
        React.createElement(react_ui_1.ButtonGroup, { className: "u-Space" },
            React.createElement(react_ui_1.Button, { type: "submit", minWidth: "wide" }, props.optionId ? getLabel('Save') : getLabel('Add')),
            React.createElement(react_ui_1.Button, { onClick: props.onCancel, autoFocus: !!props.currentOption }, getLabel('Cancel')))));
};
var DEFAULT_STATE = {
    id: '123',
    label: '',
    infoText: '',
    isContainer: false,
    pathType: 'form',
    targetPath: '',
    priority: 0,
    categories: [],
    recipientIds: [],
    emailId: '',
};
var formSlice = (0, toolkit_1.createSlice)({
    name: 'form',
    initialState: DEFAULT_STATE,
    reducers: {
        setStringValue: function (state, action) {
            state[action.payload.key] = action.payload.value;
        },
        changePathType: function (state, action) {
            if (!state.isContainer) {
                state.pathType = action.payload;
                state.targetPath = '';
            }
        },
        addCategory: function (state, action) {
            if (!state.isContainer) {
                state.categories.push(action.payload);
            }
        },
        removeCategory: function (state, action) {
            if (!state.isContainer) {
                state.categories.splice(state.categories.findIndex(function (category) { return category.id === action.payload; }), 1);
            }
        },
        addRecipient: function (state, action) {
            if (!state.isContainer) {
                state.recipientIds.push(action.payload);
            }
        },
        removeRecipient: function (state, action) {
            if (!state.isContainer) {
                state.recipientIds.splice(state.recipientIds.indexOf(action.payload), 1);
            }
        },
        changeIsContainer: function (state) {
            // assumption: a container option cannot change to result unless all its children is removed
            if (state.isContainer) {
                if ((0, highway_selectors_1.computeOptionChildrenCount)(state) > 0) {
                    throw new Error("container option cannot change type when there is children");
                }
                return __assign(__assign({}, DEFAULT_STATE), { id: state.id, label: state.label, infoText: state.infoText });
            }
            else {
                return {
                    id: state.id,
                    label: state.label,
                    infoText: state.infoText,
                    isContainer: true,
                    sections: [
                        {
                            childrenLabel: '',
                            childrenIds: [],
                        },
                    ],
                    faqUrl: '',
                    allowMultipleChildren: false,
                };
            }
        },
        reset: function () { return (__assign(__assign({}, DEFAULT_STATE), { id: (0, uuid_1.v4)() })); },
        sync: function (_, action) { return action.payload; },
    },
});
var formActions = formSlice.actions;
var connector = (0, react_redux_1.connect)(function (state, ownProps) {
    return {
        currentOption: ownProps.optionId && state.highway.optionValues[ownProps.optionId],
    };
});
exports.HighwayConfigOptionForm = connector(HighwayConfigOptionFormView);
