"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HighwayConfigList = void 0;
var react_ui_1 = require("@flos/react-ui");
var React = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var highway_slice_1 = require("../store/highway-slice");
var highway_selectors_1 = require("../store/highway.selectors");
var highway_config_option_form_1 = require("./highway-config-option-form");
var highway_config_option_list_1 = require("./highway-config-option-list");
var highway_config_slice_1 = require("./highway-config-slice");
var lib_1 = require("../lib");
var HighwayConfigListView = function (props) {
    var getLabel = (0, lib_1.useLabel)();
    var _a = React.useReducer(highway_config_slice_1.configListReducer, highway_config_slice_1.initialState), state = _a[0], dispatch = _a[1];
    var _b = React.useState(''), activeOptionId = _b[0], setActiveOptionId = _b[1];
    var parentDetails = props.values[props.parentId];
    if (!parentDetails || !parentDetails.isContainer) {
        throw new Error("Has parent but parent is not container. This is probably bug in the code.");
    }
    var hasGrandChild = !!parentDetails &&
        parentDetails.sections.some(function (section) {
            return section.childrenIds.some(function (childId) { return props.values[childId].isContainer; });
        });
    React.useEffect(function () {
        // clear activeId when parent id change
        if (activeOptionId) {
            setActiveOptionId('');
        }
    }, [props.parentId]);
    var scrollTarget = (0, react_ui_1.useScrollOnMount)();
    var depth = props.depth || 0;
    return (React.createElement(React.Fragment, null,
        React.createElement("hr", null),
        React.createElement("div", { className: "container", "data-testid": "config-depth-".concat(depth) },
            React.createElement("div", { className: "btn-toolbar" },
                React.createElement(react_ui_1.TextField, { label: "Place an FAQ accordion", placeholder: "The path from HIPPO content-block to the faq document, fx /skade/content/retshjaelp-faq", onChange: function (event) {
                        var value = event.target.value;
                        props.setOptionFaqUrl(value);
                    }, value: parentDetails.faqUrl })),
            React.createElement(react_ui_1.CheckboxField, { value: "allowMultiple", checked: parentDetails.allowMultipleChildren, onChange: function (event) {
                    var value = event.target.value;
                    props.setAllowMultiple(value);
                }, label: getLabel('Allow multiple'), disabled: hasGrandChild, "data-testid": "multiple-option-".concat(depth) }),
            parentDetails.allowMultipleChildren && (React.createElement("div", { className: "btn-toolbar" },
                React.createElement(react_ui_1.Button, { onClick: props.addSection, minWidth: "wider" }, getLabel('Add Section')))),
            props.sections.map(function (section, sectionIndex) { return (React.createElement(react_ui_1.Section, { ref: sectionIndex === 0 ? scrollTarget : undefined, key: sectionIndex },
                React.createElement(react_ui_1.TextField, { value: section.childrenLabel, autoFocus: !section.childrenLabel, onChange: function (event) {
                        var value = event.target.value;
                        props.setLabel(value, sectionIndex);
                    }, required: true, label: getLabel('Section heading') }),
                React.createElement(react_ui_1.ButtonGroup, null,
                    React.createElement(react_ui_1.Button, { onClick: function () {
                            return dispatch(highway_config_slice_1.configListActions.showDialog({ sectionIndex: sectionIndex }));
                        }, minWidth: "wide", "data-testid": "add-btn-depth".concat(depth, "-index").concat(sectionIndex) }, getLabel('Add Option')),
                    sectionIndex > 0 &&
                        parentDetails.allowMultipleChildren &&
                        section.childrenIds.length === 0 && (React.createElement(react_ui_1.Button, { onClick: function () { return props.removeSection(sectionIndex); }, variant: "secondary" // Really DANGER button
                     }, getLabel('Remove Section')))),
                React.createElement(highway_config_option_list_1.HighwayConfigOptionList, { options: section.options, activeOptionId: activeOptionId, setActiveOptionId: setActiveOptionId, allowMultiple: parentDetails.allowMultipleChildren, onSortEnd: function (order) {
                        return props.reorder(order.oldIndex, order.newIndex, sectionIndex);
                    }, onEditOption: function (optionId) {
                        return dispatch(highway_config_slice_1.configListActions.showEditDialog({ optionId: optionId }));
                    }, onDelete: function (optionId) {
                        if (activeOptionId === optionId) {
                            setActiveOptionId('');
                        }
                        props.deleteOption(optionId, sectionIndex);
                    }, axis: "xy" }))); })),
        activeOptionId && React.createElement(exports.HighwayConfigList, { parentId: activeOptionId, depth: depth + 1 }),
        React.createElement(react_ui_1.Modal, { isOpen: state.showDialog, onDismiss: function () { return dispatch(highway_config_slice_1.configListActions.closeDialog()); } }, state.showDialog && (React.createElement(highway_config_option_form_1.HighwayConfigOptionForm, { onSubmit: function (values) {
                if (state.optionId) {
                    props.updateOption(values);
                }
                else {
                    props.addOption(
                    // default to allowMultipleChildren starting level 3 (children of index 1)
                    props.depth && values.isContainer
                        ? __assign(__assign({}, values), { allowMultipleChildren: true }) : values, state.sectionIndex);
                }
                dispatch(highway_config_slice_1.configListActions.closeDialog());
            }, optionId: state.optionId, onCancel: function () { return dispatch(highway_config_slice_1.configListActions.closeDialog()); }, isMultipleOption: !!parentDetails && parentDetails.allowMultipleChildren })))));
};
var connector = (0, react_redux_1.connect)(function (state, ownProps) {
    return {
        sections: (0, highway_selectors_1.getParentSections)(state, ownProps.parentId),
        values: (0, highway_selectors_1.getHighwayOptionValues)(state),
    };
}, function (dispatch, ownProps) {
    return {
        addOption: function (option, sectionIndex) {
            return dispatch(highway_slice_1.highwayActions.addOption({ option: option, parentId: ownProps.parentId, sectionIndex: sectionIndex }));
        },
        updateOption: function (values) { return dispatch(highway_slice_1.highwayActions.setOption(values)); },
        addSection: function () { return dispatch(highway_slice_1.highwayActions.addOptionSection({ id: ownProps.parentId })); },
        removeSection: function (sectionIndex) {
            return dispatch(highway_slice_1.highwayActions.removeOptionSection({ id: ownProps.parentId, sectionIndex: sectionIndex }));
        },
        setLabel: function (label, sectionIndex) {
            return dispatch(highway_slice_1.highwayActions.setOptionChildrenLabel({
                id: ownProps.parentId,
                sectionIndex: sectionIndex,
                label: label,
            }));
        },
        setOptionFaqUrl: function (pathToFaq) {
            return dispatch(highway_slice_1.highwayActions.setOptionFaqUrl({ id: ownProps.parentId, pathToFaq: pathToFaq }));
        },
        setAllowMultiple: function (allowMultiple) {
            return dispatch(highway_slice_1.highwayActions.setOptionAllowMultipleChildren({
                id: ownProps.parentId,
                allowMultiple: allowMultiple,
            }));
        },
        reorder: function (oldIndex, newIndex, sectionIndex) {
            dispatch(highway_slice_1.highwayActions.reorderOption({
                sectionIndex: sectionIndex,
                source: oldIndex,
                destination: newIndex,
                parentId: ownProps.parentId,
            }));
        },
        deleteOption: function (optionId, sectionIndex) {
            return dispatch(highway_slice_1.highwayActions.deleteOption({
                id: optionId,
                parentId: ownProps.parentId,
                sectionIndex: sectionIndex,
            }));
        },
    };
});
exports.HighwayConfigList = connector(HighwayConfigListView);
