"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecipientConfig = void 0;
var react_ui_1 = require("@flos/react-ui");
var React = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var recipient_config_form_1 = require("../components/recipient-config-form");
var highway_selectors_1 = require("../store/highway.selectors");
var recipient_slice_1 = require("../store/recipient-slice");
var recipient_selectors_1 = require("../store/recipient.selectors");
var recipient_list_1 = require("../components/recipient-list");
var RecipientConfigView = function (props) {
    var _a = React.useState(false), showAddRecipientForm = _a[0], setShowAddRecipientForm = _a[1];
    var addRecipientBtnRef = React.useRef(null);
    var closeDialog = function () {
        setShowAddRecipientForm(false);
        if (addRecipientBtnRef.current) {
            addRecipientBtnRef.current.focus();
        }
    };
    return (React.createElement(react_ui_1.Section, null,
        React.createElement(react_ui_1.SectionHeader, null, "Claim Recipient"),
        React.createElement(react_ui_1.ButtonGroup, null,
            React.createElement(react_ui_1.Button, { onClick: function () { return setShowAddRecipientForm(true); }, minWidth: "wide", ref: addRecipientBtnRef }, "Add Recipient")),
        React.createElement(react_ui_1.Modal, { isOpen: showAddRecipientForm, onDismiss: closeDialog }, showAddRecipientForm && (React.createElement(recipient_config_form_1.RecipientConfigForm, { onSubmit: function (newRecipient) {
                props.addRecipient(newRecipient);
                closeDialog();
            }, onCancel: closeDialog }))),
        React.createElement(recipient_list_1.RecipientList, { recipients: props.recipients, options: props.options, onUpdateRecipient: props.updateRecipient, onRemoveRecipient: props.removeRecipient })));
};
var connector = (0, react_redux_1.connect)(function (state) {
    return {
        recipients: (0, recipient_selectors_1.getRecipients)(state),
        options: (0, highway_selectors_1.getHighwayResultOptions)(state),
    };
}, recipient_slice_1.recipientActions);
exports.RecipientConfig = connector(RecipientConfigView);
