"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailConfigForm = void 0;
var react_ui_1 = require("@flos/react-ui");
var React = __importStar(require("react"));
var lib_1 = require("../lib");
var EmailConfigForm = function (props) {
    var id = (0, lib_1.useId)(props.currentEmail && props.currentEmail.id);
    var _a = React.useState(props.currentEmail ? props.currentEmail.uid : ''), uid = _a[0], setUid = _a[1];
    var _b = React.useState(props.currentEmail ? props.currentEmail.title : ''), title = _b[0], setTitle = _b[1];
    var _c = React.useState(props.currentEmail ? props.currentEmail.sender : ''), sender = _c[0], setSender = _c[1];
    var _d = React.useState(props.currentEmail ? props.currentEmail.header : ''), header = _d[0], setHeader = _d[1];
    var _e = React.useState(props.currentEmail ? props.currentEmail.intro : ''), intro = _e[0], setIntro = _e[1];
    var _f = React.useState(props.currentEmail ? props.currentEmail.department : ''), department = _f[0], setDepartment = _f[1];
    var _g = React.useState(props.currentEmail ? props.currentEmail.mobile : ''), mobile = _g[0], setMobile = _g[1];
    var _h = React.useState(props.currentEmail ? props.currentEmail.link : ''), link = _h[0], setLink = _h[1];
    var _j = React.useState(props.currentEmail ? props.currentEmail.linkText : ''), linkText = _j[0], setLinkText = _j[1];
    var isUidUnique = React.useMemo(function () { return (props.currentEmail ? true : !props.allEmails.some(function (email) { return email.uid === uid; })); }, [uid, props.allEmails, props.currentEmail]);
    return (React.createElement("form", { onSubmit: function (ev) {
            ev.preventDefault();
            if (isUidUnique) {
                props.onSubmit({
                    id: id,
                    uid: uid,
                    title: title,
                    sender: sender,
                    header: header,
                    intro: intro,
                    department: department,
                    mobile: mobile,
                    link: link,
                    linkText: linkText,
                });
            }
        } },
        React.createElement("legend", null, props.currentEmail ? 'Edit Email' : 'Add Email'),
        React.createElement(react_ui_1.TextField, { label: "Id", value: uid, onChange: function (event) {
                var value = event.target.value;
                setUid(value);
            }, isValid: isUidUnique ? undefined : false, required: true, pattern: "[a-zA-Z0-9_]+", autoFocus: !props.currentEmail, readOnly: !!props.currentEmail, errorText: isUidUnique ? 'Space is not allowed' : 'This Id has been used' }),
        React.createElement(react_ui_1.TextField, { label: "Title", value: title, onChange: function (event) {
                var value = event.target.value;
                setTitle(value);
            }, required: true }),
        React.createElement(react_ui_1.TextField, { label: "Header", value: header, onChange: function (event) {
                var value = event.target.value;
                setHeader(value);
            } }),
        React.createElement(react_ui_1.TextareaField, { label: "Intro", value: intro, onChange: function (event) {
                var value = event.target.value;
                setIntro(value);
            }, maxLength: 1000 }),
        React.createElement(react_ui_1.TextField, { label: "Sender", type: "email", value: sender, onChange: function (event) {
                var value = event.target.value;
                setSender(value);
            }, required: true }),
        React.createElement(react_ui_1.TextField, { label: "Afdeling", value: department, onChange: function (event) {
                var value = event.target.value;
                setDepartment(value);
            } }),
        React.createElement(react_ui_1.TextField, { label: "Mobilenummer", value: mobile, onChange: function (event) {
                var value = event.target.value;
                setMobile(value);
            } }),
        React.createElement(react_ui_1.TextField, { label: "Link", value: link, onChange: function (event) {
                var value = event.target.value;
                setLink(value);
            } }),
        React.createElement(react_ui_1.TextField, { label: "Link tekst", value: linkText, onChange: function (event) {
                var value = event.target.value;
                setLinkText(value);
            } }),
        React.createElement(react_ui_1.ButtonGroup, null,
            React.createElement(react_ui_1.Button, { type: "submit", minWidth: "wide" }, props.currentEmail ? 'Save' : 'Add'),
            React.createElement(react_ui_1.Button, { onClick: props.onCancel }, "Cancel"))));
};
exports.EmailConfigForm = EmailConfigForm;
