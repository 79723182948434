"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHasCategoryChange = exports.getCategoryOptions = exports.getCategories = exports.getCategoryState = void 0;
var reselect_1 = require("reselect");
var getCategoryState = function (state) { return state.category; };
exports.getCategoryState = getCategoryState;
exports.getCategories = (0, reselect_1.createSelector)(exports.getCategoryState, function (_a) {
    var categoryIds = _a.categoryIds, categoryById = _a.categoryById, lossTypeById = _a.lossTypeById;
    var allLossTypes = Object.values(lossTypeById);
    return categoryIds.map(function (id) { return (__assign(__assign({}, categoryById[id]), { lossTypes: allLossTypes.filter(function (lossType) { return lossType.categoryId === id; }) })); });
});
exports.getCategoryOptions = (0, reselect_1.createSelector)(exports.getCategoryState, function (_, selectedValues) { return selectedValues; }, function (_a, selectedValues) {
    var categoryIds = _a.categoryIds, categoryById = _a.categoryById, lossTypeById = _a.lossTypeById;
    var options = [];
    var allLossTypes = Object.values(lossTypeById);
    categoryIds.forEach(function (categoryId) {
        var category = categoryById[categoryId];
        var value = "".concat(category.key, ": ").concat(category.nameInDanish, "/").concat(category.nameInEnglish);
        options.push({
            id: category.id,
            label: value,
            value: value,
            category: category,
            disabled: selectedValues.some(function (value) { return value.id === category.id; }),
        });
        allLossTypes
            .filter(function (lossType) { return lossType.categoryId === categoryId; })
            .forEach(function (lossType) {
            var lossTypeValue = "".concat(lossType.key, ": ").concat(lossType.titleInDanish, "/").concat(lossType.titleInEnglish);
            options.push({
                id: lossType.id,
                label: lossTypeValue,
                value: lossTypeValue,
                category: category,
                lossType: lossType,
                indent: true,
                disabled: selectedValues.some(function (value) { return value.id === lossType.id; }),
            });
        });
    });
    return options;
});
exports.getHasCategoryChange = (0, reselect_1.createSelector)(exports.getCategoryState, function (_a) {
    var categoryById = _a.categoryById, categoryIds = _a.categoryIds, lossTypeById = _a.lossTypeById, oriState = _a.oriState;
    if (!oriState) {
        return true;
    }
    return (categoryById !== oriState.categoryById ||
        categoryIds !== oriState.categoryIds ||
        lossTypeById !== oriState.lossTypeById);
});
