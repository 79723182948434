"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryConfigForm = void 0;
var react_ui_1 = require("@flos/react-ui");
var React = __importStar(require("react"));
var lib_1 = require("../lib");
var CategoryConfigForm = function (props) {
    var getLabel = (0, lib_1.useLabel)();
    var id = (0, lib_1.useId)(props.currentCategory && props.currentCategory.id);
    var _a = React.useState(props.currentCategory ? props.currentCategory.nameInDanish : ''), nameInDanish = _a[0], setNameInDanish = _a[1];
    var _b = React.useState(props.currentCategory ? props.currentCategory.nameInEnglish : ''), nameInEnglish = _b[0], setNameInEnglish = _b[1];
    var _c = React.useState(props.currentCategory ? props.currentCategory.key : ''), key = _c[0], setKey = _c[1];
    return (React.createElement("form", { onSubmit: function (ev) {
            ev.preventDefault();
            props.onSubmit({
                id: id,
                nameInDanish: nameInDanish,
                nameInEnglish: nameInEnglish,
                key: key,
            });
        } },
        React.createElement("legend", null, props.currentCategory ? 'Edit Category' : 'Add Category'),
        React.createElement(react_ui_1.TextField, { label: "Claim Category (DA)", value: nameInDanish, onChange: function (event) {
                var value = event.target.value;
                setNameInDanish(value);
            }, required: true, autoFocus: !props.currentCategory }),
        React.createElement(react_ui_1.TextField, { label: "Claim Category (EN)", value: nameInEnglish, onChange: function (event) {
                var value = event.target.value;
                setNameInEnglish(value);
            }, required: true }),
        React.createElement(react_ui_1.TextField, { label: "Claim Category Key", value: key, onChange: function (event) {
                var value = event.target.value;
                setKey(value);
            }, required: true }),
        React.createElement(react_ui_1.ButtonGroup, null,
            React.createElement(react_ui_1.Button, { type: "submit", minWidth: "wide" }, props.currentCategory ? getLabel('Save') : getLabel('Add')),
            React.createElement(react_ui_1.Button, { onClick: props.onCancel }, getLabel('Cancel')))));
};
exports.CategoryConfigForm = CategoryConfigForm;
