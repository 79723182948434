"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailConfig = void 0;
var react_ui_1 = require("@flos/react-ui");
var React = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var email_config_form_1 = require("../components/email-config-form");
var email_slice_1 = require("../store/email-slice");
var email_selector_1 = require("../store/email.selector");
var highway_selectors_1 = require("../store/highway.selectors");
var email_list_1 = require("../components/email-list");
var EmailConfigView = function (props) {
    var addBtnRef = React.useRef(null);
    var _a = React.useState(false), showForm = _a[0], setShowForm = _a[1];
    var closeDialog = function () {
        setShowForm(false);
        if (addBtnRef.current) {
            addBtnRef.current.focus();
        }
    };
    return (React.createElement(react_ui_1.Section, null,
        React.createElement(react_ui_1.SectionHeader, null, "Email"),
        React.createElement(react_ui_1.ButtonGroup, null,
            React.createElement(react_ui_1.Button, { onClick: function () { return setShowForm(true); }, minWidth: "wide", ref: addBtnRef }, "Add Email")),
        React.createElement(react_ui_1.Modal, { isOpen: showForm, onDismiss: closeDialog }, showForm && (React.createElement(email_config_form_1.EmailConfigForm, { onSubmit: function (newEmail) {
                props.addEmail(newEmail);
                closeDialog();
            }, allEmails: props.emails, onCancel: closeDialog }))),
        React.createElement(email_list_1.EmailList, { emails: props.emails, options: props.options, onUpdateEmail: props.updateEmail, onRemoveEmail: props.removeEmail })));
};
var connector = (0, react_redux_1.connect)(function (state) {
    return {
        emails: (0, email_selector_1.getEmails)(state),
        options: (0, highway_selectors_1.getHighwayResultOptions)(state),
    };
}, email_slice_1.emailActions);
exports.EmailConfig = connector(EmailConfigView);
