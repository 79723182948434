"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecipientList = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var react_ui_1 = require("@flos/react-ui");
var React = __importStar(require("react"));
var delete_confirmation_1 = require("./delete-confirmation");
var recipient_config_form_1 = require("./recipient-config-form");
var record_list_1 = require("./record-list");
var RecipientList = function (props) {
    var _a = React.useReducer(recipientListReducer, initialState), state = _a[0], dispatch = _a[1];
    var onDismissDialog = function () { return dispatch(actions.dismiss()); };
    var isAllowed = React.useMemo(function () {
        if (state.mode === 'delete') {
            return props.options.every(function (option) { return !option.recipientIds.includes(state.focused.id); });
        }
        return true;
    }, [state.mode, state.focused, props.options]);
    return (React.createElement(React.Fragment, null,
        React.createElement(record_list_1.RecordList, { className: "u-Space" }, props.recipients.map(function (recipient) { return (React.createElement(record_list_1.RecordItem, { title: React.createElement(React.Fragment, null,
                React.createElement("b", null, recipient.code),
                " ",
                recipient.name), onEdit: function () { return dispatch(actions.editRecipient(recipient)); }, onRemove: function () { return dispatch(actions.deleteRecipient(recipient)); }, "data-testid": recipient.code, key: recipient.id })); })),
        React.createElement(react_ui_1.Modal, { isOpen: state.mode !== 'close', onDismiss: onDismissDialog },
            state.mode === 'edit' && (React.createElement(recipient_config_form_1.RecipientConfigForm, { currentRecipient: state.focused, onSubmit: function (updatedRecipient) {
                    props.onUpdateRecipient(updatedRecipient);
                    onDismissDialog();
                }, onCancel: onDismissDialog })),
            state.mode === 'delete' &&
                (isAllowed ? (React.createElement(delete_confirmation_1.DeleteConfirmation, { subject: React.createElement("span", null,
                        state.focused.code,
                        ": ",
                        state.focused.name), onConfirm: function () {
                        onDismissDialog();
                        props.onRemoveRecipient(state.focused.id);
                    }, onCancel: onDismissDialog })) : (React.createElement("div", null,
                    React.createElement("div", { "data-testid": "dialog-msg" },
                        React.createElement("p", null, "Deletion of this recipient is not allowed as it is used by some highway option(s) currently."),
                        React.createElement("p", null, "Remove the recipient from the option(s) before delete this category.")),
                    React.createElement(react_ui_1.ButtonGroup, null,
                        React.createElement(react_ui_1.Button, { onClick: onDismissDialog }, "OK"))))))));
};
exports.RecipientList = RecipientList;
var initialState = {
    focused: null,
    mode: 'close',
};
var recipientListSlice = (0, toolkit_1.createSlice)({
    name: 'recipientList',
    initialState: initialState,
    reducers: {
        editRecipient: function (_, _a) {
            var payload = _a.payload;
            return ({
                mode: 'edit',
                focused: payload,
            });
        },
        deleteRecipient: function (_, _a) {
            var payload = _a.payload;
            return ({
                mode: 'delete',
                focused: payload,
            });
        },
        dismiss: function () {
            return ({
                focused: null,
                mode: 'close',
            });
        },
    },
});
var recipientListReducer = recipientListSlice.reducer;
var actions = recipientListSlice.actions;
