"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rootReducer = void 0;
var redux_1 = require("redux");
var category_slice_1 = require("./category-slice");
var email_slice_1 = require("./email-slice");
var formio_slice_1 = require("./formio-slice");
var highway_slice_1 = require("./highway-slice");
var recipient_slice_1 = require("./recipient-slice");
var log_slice_1 = require("./log-slice");
exports.rootReducer = (0, redux_1.combineReducers)({
    category: category_slice_1.categoryReducer,
    formio: formio_slice_1.formioReducer,
    highway: highway_slice_1.highwayReducer,
    recipient: recipient_slice_1.recipientReducer,
    email: email_slice_1.emailReducer,
    loggedChanges: log_slice_1.logReducer,
});
