"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHasEmailChange = exports.getEmailOptions = exports.getEmails = void 0;
var reselect_1 = require("reselect");
var getEmailState = function (state) { return state.email; };
var getEmails = function (state) { return Object.values(getEmailState(state).emailById); };
exports.getEmails = getEmails;
exports.getEmailOptions = (0, reselect_1.createSelector)(exports.getEmails, function (emails) {
    return emails.map(function (email) { return ({
        id: email.id,
        value: email.title,
        label: "".concat(email.uid, ": ").concat(email.title, " (").concat(email.sender, ")"),
    }); });
});
exports.getHasEmailChange = (0, reselect_1.createSelector)(getEmailState, function (_a) {
    var emailById = _a.emailById, oriState = _a.oriState;
    if (!oriState) {
        return true;
    }
    return oriState.emailById !== emailById;
});
