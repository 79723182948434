"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreviewPage = void 0;
var react_ui_1 = require("@flos/react-ui");
var React = __importStar(require("react"));
var highway_page_1 = require("../containers/highway-page");
var lib_1 = require("../lib");
var PreviewPage = function () {
    var getLabel = (0, lib_1.useLabel)();
    var _a = React.useState(['personal', 'biz']), segments = _a[0], setSegments = _a[1];
    var _b = React.useState(''), link = _b[0], setLinkString = _b[1];
    var _c = React.useState(''), pathString = _c[0], setPathString = _c[1];
    var _d = React.useState('TD'), brand = _d[0], setBrand = _d[1];
    var _e = React.useState('open'), site = _e[0], setSite = _e[1];
    var root = React.useMemo(function () { return [brand, site]; }, [brand, site]);
    return (React.createElement(React.Fragment, null,
        React.createElement(react_ui_1.Section, null,
            React.createElement(react_ui_1.SectionHeader, null, "Settings"),
            React.createElement(react_ui_1.RadioField, { label: getLabel('Brand'), value: brand, onChange: function (event) {
                    var value = event.target.value;
                    setBrand(value);
                }, stacked: false },
                React.createElement(react_ui_1.RadioInput, { value: "TD", label: "TD" })),
            React.createElement(react_ui_1.RadioField, { label: getLabel('Site'), value: site, onChange: function (event) {
                    var value = event.target.value;
                    setSite(value);
                }, stacked: false },
                React.createElement(react_ui_1.RadioInput, { value: "open", label: "Open" }),
                React.createElement(react_ui_1.RadioInput, { value: "closed", label: "Closed" })),
            React.createElement(react_ui_1.MultiSelectField, { label: "Segment", value: segments, onChange: function (event) {
                    // YIJ
                    // Changed from onChangeValue
                    // Unsure if it is still an array
                    var values = event.target.value;
                    setSegments(values);
                }, options: ['personal', 'biz', 'agriculture'] }),
            React.createElement(react_ui_1.TextField, { label: "Express Link", value: link, onChange: function (event) {
                    var value = event.target.value;
                    setLinkString(value);
                } }),
            React.createElement(react_ui_1.ButtonGroup, null,
                React.createElement(react_ui_1.Button, { onClick: function () { return setPathString(link); } }, "Update Path String"))),
        React.createElement(highway_page_1.HighwayPage, { supportedSegments: segments, pathString: pathString, root: root, site: site })));
};
exports.PreviewPage = PreviewPage;
