"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSessionItem = exports.setSessionItem = void 0;
var STORAGE_PREFIX = 'ClaimForm2020';
function setSessionItem(key, value) {
    try {
        return window.sessionStorage.setItem("".concat(STORAGE_PREFIX, "|").concat(key), JSON.stringify(value));
    }
    catch (error) {
        console.group("Error in setSessionItem");
        console.error(error);
        console.groupEnd();
        throw error;
    }
}
exports.setSessionItem = setSessionItem;
function getSessionItem(key) {
    return window.sessionStorage.getItem("".concat(STORAGE_PREFIX, "|").concat(key));
}
exports.getSessionItem = getSessionItem;
