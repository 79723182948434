"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.logoutClearStorage = exports.callSessionService = exports.RedirectToLogin = exports.sessionHandler = exports.logoutConfigHandler = exports.validateSession = void 0;
var fetch_json_1 = require("../fetch-json");
var navigate_1 = require("../navigate");
var REQUEST, SESSION_REQUEST;
var validateSession = function () { return __awaiter(void 0, void 0, void 0, function () {
    var getUrl, getCodeParam, tokenWithQuotes, authToken, idToken, sessionStatus, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                getUrl = new URLSearchParams(window.location.search);
                getCodeParam = getUrl.get('code');
                tokenWithQuotes = localStorage.getItem('token');
                authToken = tokenWithQuotes === null || tokenWithQuotes === void 0 ? void 0 : tokenWithQuotes.replace(/['"]+/g, '');
                idToken = localStorage.getItem('idToken');
                if (!getCodeParam) return [3 /*break*/, 2];
                return [4 /*yield*/, (0, exports.callSessionService)(getCodeParam, authToken, idToken)];
            case 1:
                _a = _b.sent();
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, (0, exports.RedirectToLogin)()];
            case 3:
                _a = _b.sent();
                _b.label = 4;
            case 4:
                sessionStatus = _a;
                return [2 /*return*/, sessionStatus];
        }
    });
}); };
exports.validateSession = validateSession;
var logoutConfigHandler = function (request) {
    REQUEST = request;
};
exports.logoutConfigHandler = logoutConfigHandler;
var sessionHandler = function (request) {
    SESSION_REQUEST = request;
};
exports.sessionHandler = sessionHandler;
var RedirectToLogin = function () {
    /* const getBaseUrl: string = baseUrlFn(); */
    var url = REQUEST.url; //`https://api.${getBaseUrl}.topdanmark.cloud/claim-highway/v1/logout`;
    var params = {
        retryDelays: [],
        method: 'get',
    };
    return new Promise(function (resolve, reject) {
        (0, fetch_json_1.fetchJson)(url, params).then(function (val) {
            var _a;
            var trimUrl = (_a = val.result) === null || _a === void 0 ? void 0 : _a.logoutUrl.replace(/\s/g, '');
            resolve(trimUrl);
        }, function (error) {
            console.log(error);
            reject(error);
        });
    });
};
exports.RedirectToLogin = RedirectToLogin;
var callSessionService = function (code, token, idToken) {
    //const getBaseUrl: string = baseUrlFn();
    var url = "".concat(SESSION_REQUEST.url, "/?code=").concat(code, "&accToken=").concat(token, "&idToken=").concat(idToken); //`https://api.${getBaseUrl}.topdanmark.cloud/claim-highway/v1/validate-session/?code=${code}&accToken=${token}&idToken=${idToken}`;
    var params = {
        retryDelays: [],
        method: 'get',
    };
    return new Promise(function (resolve, reject) {
        (0, fetch_json_1.fetchJson)(url, params).then(function (val) {
            resolve(val);
        }, function (error) {
            console.log(error);
            reject(error);
        });
    });
};
exports.callSessionService = callSessionService;
var logoutClearStorage = function () { return __awaiter(void 0, void 0, Promise, function () {
    var logoutSuccess;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, exports.RedirectToLogin)()];
            case 1:
                logoutSuccess = _a.sent();
                localStorage.removeItem('token');
                localStorage.removeItem('idToken');
                /* sessionStorage.removeItem('token');
                    sessionStorage.removeItem('idToken');
                    localStorage.setItem('loggedOut', 'true'); */
                (0, navigate_1.navigate)(logoutSuccess);
                return [2 /*return*/];
        }
    });
}); };
exports.logoutClearStorage = logoutClearStorage;
