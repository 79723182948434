"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LossTypeForm = void 0;
var react_ui_1 = require("@flos/react-ui");
var React = __importStar(require("react"));
var lib_1 = require("../lib");
var LossTypeForm = function (props) {
    var id = (0, lib_1.useId)(props.currentLossType && props.currentLossType.id);
    var _a = React.useState(props.currentLossType ? props.currentLossType.titleInDanish : ''), titleInDanish = _a[0], setTitleInDanish = _a[1];
    var _b = React.useState(props.currentLossType ? props.currentLossType.titleInEnglish : ''), titleInEnglish = _b[0], setTitleInEnglish = _b[1];
    var _c = React.useState(props.currentLossType ? props.currentLossType.key : ''), key = _c[0], setKey = _c[1];
    var isCreate = !props.currentLossType;
    return (React.createElement("form", { onSubmit: function (ev) {
            ev.preventDefault();
            props.onSubmit({
                id: id,
                categoryId: props.categoryId,
                titleInDanish: titleInDanish,
                titleInEnglish: titleInEnglish,
                key: key,
            });
        } },
        React.createElement("legend", null, isCreate ? 'Add Loss Type' : 'Edit Loss Type'),
        React.createElement(react_ui_1.TextField, { label: "Loss type key", value: key, onChange: function (event) {
                var value = event.target.value;
                setKey(value);
            }, required: true, autoFocus: !props.currentLossType }),
        React.createElement(react_ui_1.TextField, { label: "Loss type title (EN)", value: titleInEnglish, onChange: function (event) {
                var value = event.target.value;
                setTitleInEnglish(value);
            }, required: true }),
        React.createElement(react_ui_1.TextField, { label: "Loss type title (DA)", value: titleInDanish, onChange: function (event) {
                var value = event.target.value;
                setTitleInDanish(value);
            }, required: true }),
        React.createElement(react_ui_1.ButtonGroup, null,
            React.createElement(react_ui_1.Button, { type: "submit", minWidth: "wide" }, isCreate ? 'Add' : 'Save'),
            React.createElement(react_ui_1.Button, { onClick: props.onCancel }, "Cancel"))));
};
exports.LossTypeForm = LossTypeForm;
