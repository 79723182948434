"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HighwayPage = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var react_ui_1 = require("@flos/react-ui");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var selectable_box_field_1 = require("../components/selectable-box-field");
var lib_1 = require("../lib");
var persistance_service_1 = require("../services/persistance-service");
var highway_selectors_1 = require("../store/highway.selectors");
var highway_page_context_1 = require("./highway-page-context");
var navigate_panel_1 = require("./navigate-panel");
var highwayFormSlice = (0, toolkit_1.createSlice)({
    name: 'highwayForm',
    initialState: [],
    reducers: {
        selectOption: function (state, _a) {
            var payload = _a.payload;
            if (state.length > payload.depth) {
                state.splice(payload.depth + 1);
                state[payload.depth] = payload.id;
            }
            else {
                state.push(payload.id);
            }
        },
        reset: function (_, _a) {
            var payload = _a.payload;
            return payload;
        },
    },
});
var actions = highwayFormSlice.actions;
var HighwayFormContext = react_1.default.createContext({
    selectedOptions: [],
    dispatch: function () { },
});
var HighwaySingleOptionList = function (props) {
    // Using local state only for the faq accordion
    var _a = (0, react_1.useState)(''), faqUrl = _a[0], setFaqUrl = _a[1];
    var _b = (0, react_1.useState)(''), faqHtml = _b[0], setFaqHtml = _b[1];
    var loadFaq = function (path) { return __awaiter(void 0, void 0, void 0, function () {
        var url, response, text;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'https://www.nordeapension.dk/htmlcontent/?&documentPath=shared/content-blocks' + path;
                    return [4 /*yield*/, fetch(url)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.text()];
                case 2:
                    text = _a.sent();
                    setFaqHtml(text);
                    return [2 /*return*/];
            }
        });
    }); };
    var _c = react_1.default.useContext(HighwayFormContext), dispatch = _c.dispatch, selectedOptions = _c.selectedOptions;
    var selectedOption = selectedOptions[props.depth] || '';
    var scrollTarget = (0, react_ui_1.useScrollOnMount)();
    var getLabel = (0, lib_1.useLabel)();
    var optionDetails = props.optionMap[selectedOption];
    var handleChange = function (value) {
        dispatch(actions.selectOption({
            depth: props.depth,
            id: value,
        }));
    };
    var options = props.options
        ? props.options.map(function (o) { return ({
            label: o.label,
            value: o.id,
        }); })
        : [];
    var navigatePanelRef = react_1.default.useRef(null);
    react_1.default.useEffect(function () {
        if (optionDetails && navigatePanelRef.current) {
            (0, react_ui_1.scrollTo)(navigatePanelRef.current);
        }
        // Whether an faq accordion should be fetched and shown
        if (optionDetails && optionDetails.isContainer && optionDetails.faqUrl) {
            setFaqUrl(optionDetails.faqUrl);
            loadFaq(optionDetails.faqUrl);
        }
        else {
            // Make sure the faq does not hang around
            setFaqUrl('');
            setFaqHtml('');
        }
    }, [optionDetails]);
    var isLastPanel = !optionDetails;
    var faq = react_1.default.createElement(react_1.default.Fragment, null);
    if (faqUrl) {
        faq = react_1.default.createElement("div", { dangerouslySetInnerHTML: { __html: faqHtml } });
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        props.options && (react_1.default.createElement(react_ui_1.Section, null,
            react_1.default.createElement(react_ui_1.SectionHeader, null, props.depth === 0 && getLabel('whatIsItAbout')),
            react_1.default.createElement(selectable_box_field_1.SelectableBoxField, { value: selectedOption, onChangeValue: function (event) {
                    var value = event.target.value;
                    handleChange(value);
                }, multiple: false, maxWidth: "wide", options: options, className: "visible-sm visible-md visible-lg" }),
            react_1.default.createElement(react_ui_1.SelectField, { value: selectedOption, onChange: function (event) {
                    var value = event.target.value;
                    handleChange(value);
                }, options: [
                    {
                        label: '',
                        value: '',
                    },
                ].concat(options), className: "visible-xs" }),
            isLastPanel && react_1.default.createElement("div", { className: "visible-xs u-Space--lg" }))),
        props.sections &&
            props.sections.map(function (section, index, sections) { return (react_1.default.createElement(react_ui_1.Section, { ref: index === 0 ? scrollTarget : undefined, key: index },
                react_1.default.createElement(react_ui_1.SectionHeader, null, section.childrenLabel),
                react_1.default.createElement(selectable_box_field_1.SelectableBoxField, { value: selectedOption, onChangeValue: function (value) {
                        handleChange(value);
                    }, multiple: false, options: section.options.map(function (o) { return ({
                        label: o.label,
                        value: o.id,
                        description: o.infoText,
                    }); }), maxWidth: "wide", className: "visible-sm visible-md visible-lg" }),
                faq,
                react_1.default.createElement(react_ui_1.SelectField, { value: selectedOption, onChange: function (event) {
                        var value = event.target.value;
                        handleChange(value);
                    }, options: [
                        {
                            label: '',
                            value: '',
                        },
                    ].concat(section.options.map(function (o) { return ({
                        label: o.label,
                        value: o.id,
                    }); })), className: "visible-xs" }),
                isLastPanel && index === sections.length - 1 && (react_1.default.createElement("div", { className: "visible-xs u-Space--lg" })))); }),
        optionDetails &&
            (optionDetails.isContainer ? (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(HighwayOptionList, { parentId: selectedOption, root: props.root, depth: props.depth + 1 }))) : (react_1.default.createElement(navigate_panel_1.NavigatePanel, { result: optionDetails, selectedOptions: selectedOptions, isMultiple: false, ref: navigatePanelRef })))));
};
var HighwayMultipleOptionList = function (props) {
    var scrollTarget = (0, react_ui_1.useScrollOnMount)();
    var _a = react_1.default.useContext(HighwayFormContext), dispatch = _a.dispatch, allSelectedOptions = _a.selectedOptions;
    var selectedOptions = allSelectedOptions[props.depth] || [];
    var optionDetails = selectedOptions
        .map(function (opt) { return props.optionMap[opt]; })
        .reduce(function (selected, option) {
        return !selected || selected.priority > option.priority ? option : selected;
    }, undefined);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        props.sections &&
            props.sections.map(function (section, index) { return (react_1.default.createElement(react_ui_1.Section, { ref: props.parentDetails ? scrollTarget : undefined, key: index },
                section.childrenLabel && (react_1.default.createElement(react_ui_1.SectionHeader, null, section.childrenLabel)),
                react_1.default.createElement(selectable_box_field_1.SelectableBoxField, { value: selectedOptions, onChangeValue: function (options) {
                        dispatch(actions.selectOption({
                            depth: props.depth,
                            id: options,
                        }));
                    }, multiple: true, maxWidth: "wide", options: section.options.map(function (o) { return ({
                        label: o.label,
                        value: o.id,
                        description: o.infoText,
                    }); }) }))); }),
        optionDetails && (react_1.default.createElement(navigate_panel_1.NavigatePanel, { result: optionDetails, selectedOptions: allSelectedOptions, isMultiple: selectedOptions.length > 1 }))));
};
var HighwayOptionListView = function (props) {
    var parentDetails = props.parentId ? props.optionMap[props.parentId] : undefined;
    return parentDetails && parentDetails.isContainer && parentDetails.allowMultipleChildren ? (react_1.default.createElement(HighwayMultipleOptionList, __assign({}, props, { parentDetails: parentDetails }))) : (react_1.default.createElement(HighwaySingleOptionList, __assign({}, props, { parentDetails: parentDetails })));
};
var connector = (0, react_redux_1.connect)(function (state, ownProps) {
    return {
        options: ownProps.parentId
            ? undefined
            : (0, highway_selectors_1.getRootOptions)(state, ownProps.segment, ownProps.root),
        sections: ownProps.parentId ? (0, highway_selectors_1.getParentSections)(state, ownProps.parentId) : undefined,
        optionMap: state.highway.optionValues,
        optionPath: ownProps.parentId ? (0, highway_selectors_1.getOptionPath)(state, ownProps.parentId) : undefined,
    };
});
var HighwayOptionList = connector(HighwayOptionListView);
var HighwayPage = function (_a) {
    var supportedSegments = _a.supportedSegments, _b = _a.pathString, pathString = _b === void 0 ? '' : _b, site = _a.site, root = _a.root;
    var getLabel = (0, lib_1.useLabel)();
    var _c = react_1.default.useReducer(highwayFormSlice.reducer, [], function () {
        var storedState = (0, persistance_service_1.hydrateState)();
        return storedState && (0, react_ui_1.includes)(supportedSegments, storedState.segment)
            ? storedState.selectedOptionIds
            : [];
    }), selectedOptions = _c[0], dispatch = _c[1];
    var highwayFormState = react_1.default.useMemo(function () { return ({
        selectedOptions: selectedOptions,
        dispatch: dispatch,
    }); }, [selectedOptions, dispatch]);
    var _d = react_1.default.useState(function () {
        var storedState = (0, persistance_service_1.hydrateState)();
        return storedState && (0, react_ui_1.includes)(supportedSegments, storedState.segment)
            ? storedState.segment
            : '';
    }), segment = _d[0], setSegment = _d[1];
    var updateSegment = function (newSegment, newOptions) {
        if (newOptions === void 0) { newOptions = []; }
        setSegment(newSegment);
        dispatch(actions.reset(newOptions));
    };
    react_1.default.useEffect(function () {
        /*
    in actual highway, supportedSegment will not be changed.
    the syncing logic here is to support the scenario of preview, which
    user can update supportedSegment
    */
        if ((segment && !(0, react_ui_1.includes)(supportedSegments, segment)) ||
            (!segment && supportedSegments.length === 1)) {
            updateSegment(supportedSegments[0]);
        }
    }, [supportedSegments, segment]);
    var parseParams = react_1.default.useMemo(function () { return ({ pathString: pathString, root: root }); }, [pathString, root]);
    var parsedResult = (0, react_redux_1.useSelector)(function (state) { return pathString && (0, highway_selectors_1.parsePathString)(state, parseParams); });
    react_1.default.useEffect(function () {
        if (parsedResult) {
            var parsedSegment = parsedResult[0], parsedOptionIds = parsedResult[1];
            if ((0, react_ui_1.includes)(supportedSegments, parsedSegment)) {
                if (parsedSegment !== segment) {
                    updateSegment(parsedSegment, parsedOptionIds);
                }
                else {
                    dispatch(actions.reset(parsedOptionIds));
                }
            }
        }
    }, [parsedResult]);
    return (react_1.default.createElement(HighwayFormContext.Provider, { value: highwayFormState },
        react_1.default.createElement(highway_page_context_1.SegmentContext.Provider, { value: segment },
            react_1.default.createElement(highway_page_context_1.SiteContext.Provider, { value: site },
                supportedSegments.length > 1 && (react_1.default.createElement(react_ui_1.Section, null,
                    react_1.default.createElement(selectable_box_field_1.SelectableBoxField, { value: segment, onChangeValue: function (value) { return updateSegment(value); }, options: supportedSegments.map(function (option) { return ({
                            value: option,
                            label: getLabel(option),
                        }); }), multiple: false }))),
                segment && react_1.default.createElement(HighwayOptionList, { segment: segment, root: root, depth: 0 })))));
};
exports.HighwayPage = HighwayPage;
