"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeHighwaySelectionResults = exports.getHasUnsavedChanges = exports.parsePathString = exports.generatePathString = exports.getOptionPath = exports.getRootOptions = exports.getRootAgriOptions = exports.getRootBizOptions = exports.getPersonalOptions = exports.getParentSections = exports.computeOptionChildrenCount = exports.getOption = exports.getHighwayResultOptions = exports.getHighwayOptionValues = exports.getHighwayState = void 0;
var react_ui_1 = require("@flos/react-ui");
var reselect_1 = require("reselect");
var getHighwayState = function (state) { return state.highway; };
exports.getHighwayState = getHighwayState;
var getHighwayOptionValues = function (state) { return (0, exports.getHighwayState)(state).optionValues; };
exports.getHighwayOptionValues = getHighwayOptionValues;
exports.getHighwayResultOptions = (0, reselect_1.createSelector)(exports.getHighwayOptionValues, function (optionValues) {
    return Object.values(optionValues).filter(function (option) { return !option.isContainer; });
});
var getPersonalRootOptionIds = function (state, rootDetails) {
    return (0, exports.getHighwayState)(state)
        .roots.filter(function (root) { return root.brand === rootDetails[0] && root.site === rootDetails[1]; })
        .map(function (root) { return root.options.personal; })[0];
};
var getBizRootOptionIds = function (state, rootDetails) {
    return (0, exports.getHighwayState)(state)
        .roots.filter(function (root) { return root.brand === rootDetails[0] && root.site === rootDetails[1]; })
        .map(function (root) { return root.options.biz; })[0];
};
var getAgridRootOptionIds = function (state, rootDetails) {
    return (0, exports.getHighwayState)(state)
        .roots.filter(function (root) { return root.brand === rootDetails[0] && root.site === rootDetails[1]; })
        .map(function (root) { return root.options.agriculture; })[0];
};
function getOption(state, id) {
    return id ? (0, exports.getHighwayState)(state).optionValues[id] : undefined;
}
exports.getOption = getOption;
var computeOption = function (optionIds, values) {
    return optionIds.map(function (id) { return values[id]; });
};
var computeOptionChildrenCount = function (option) {
    return option.sections.reduce(function (total, section) { return total + section.childrenIds.length; }, 0);
};
exports.computeOptionChildrenCount = computeOptionChildrenCount;
var getParentSections = function (state, parentId) {
    var parent = getOption(state, parentId);
    if (!parent) {
        return [];
    }
    if (!parent.isContainer) {
        throw new Error("This should not happen. parent options should always be available.");
    }
    return parent.sections.map(function (section) { return (__assign(__assign({}, section), { options: section.childrenIds.map(function (childId) { return getOption(state, childId); }) })); });
};
exports.getParentSections = getParentSections;
exports.getPersonalOptions = (0, reselect_1.createSelector)(getPersonalRootOptionIds, exports.getHighwayOptionValues, computeOption);
exports.getRootBizOptions = (0, reselect_1.createSelector)(getBizRootOptionIds, exports.getHighwayOptionValues, computeOption);
exports.getRootAgriOptions = (0, reselect_1.createSelector)(getAgridRootOptionIds, exports.getHighwayOptionValues, computeOption);
var getRootOptions = function (state, segment, rootDetails) {
    switch (segment) {
        case 'personal':
            return (0, exports.getPersonalOptions)(state, rootDetails);
        case 'biz':
            return (0, exports.getRootBizOptions)(state, rootDetails);
        case 'agriculture':
            return (0, exports.getRootAgriOptions)(state, rootDetails);
        default:
            throw new Error("segment is not specified in getRootOptions");
    }
};
exports.getRootOptions = getRootOptions;
var getRootOptionIds = (0, reselect_1.createSelector)(getPersonalRootOptionIds, getBizRootOptionIds, getAgridRootOptionIds, function (personalOptionIds, bizOptionIds, agridOptionIds) {
    return personalOptionIds.concat(bizOptionIds).concat(agridOptionIds);
});
/**
 * get the paths to go to a specific option
 */
var getOptionPath = function (state, optionId) {
    var rootOptionIds = (0, react_ui_1.concatAll)((0, exports.getHighwayState)(state).roots.map(function (root) { return getRootOptionIds(state, [root.brand, root.site]); }));
    var options = Object.values((0, exports.getHighwayOptionValues)(state));
    var findOptionPath = function (id) {
        if ((0, react_ui_1.includes)(rootOptionIds, id)) {
            return [id];
        }
        var result = options.find(function (option) {
            return option.isContainer &&
                option.sections.some(function (section) { return (0, react_ui_1.includes)(section.childrenIds, id); });
        });
        if (!result) {
            throw new Error('optionId is not child of any parent option. This is probably a bug');
        }
        return findOptionPath(result.id).concat(result.isContainer && result.allowMultipleChildren ? [[id]] : id);
    };
    return findOptionPath(optionId);
};
exports.getOptionPath = getOptionPath;
var sanitizeLabel = function (label) { return label.replace(/\-|,|\[|\]/g, '').replace(/\s/g, '_'); };
var SEPARATOR = '-';
var ITEM_SEPARATOR = ',';
var computePathString = function (optionValues, optionPath) {
    return optionPath
        .map(function (path) {
        return Array.isArray(path) ? path.map(function (p) { return optionValues[p]; }) : optionValues[path];
    })
        .filter(react_ui_1.isDefined)
        .map(function (option) {
        return Array.isArray(option)
            ? "[".concat(option
                .map(function (opt) { return encodeURIComponent(sanitizeLabel(opt.label)); })
                .join(ITEM_SEPARATOR), "]")
            : encodeURIComponent(sanitizeLabel(option.label));
    })
        .join(SEPARATOR);
};
var generatePathString = function (state, optionId, segment) {
    var values = (0, exports.getHighwayOptionValues)(state);
    var paths = (0, exports.getOptionPath)(state, optionId);
    return "".concat(segment).concat(SEPARATOR).concat(computePathString(values, paths));
};
exports.generatePathString = generatePathString;
function pickOptionsId(options) {
    return options
        .filter(react_ui_1.isDefined)
        .map(function (option) { return (Array.isArray(option) ? pickOptionsId(option) : option.id); });
}
exports.parsePathString = (0, reselect_1.createSelector)(function (state) { return Object.values((0, exports.getHighwayOptionValues)(state)); }, function (_, _a) {
    var pathString = _a.pathString;
    return pathString;
}, function (state, _a) {
    var root = _a.root;
    return getRootOptionIds(state, root);
}, function (options, pathString, rootOptionIds) {
    var _a = pathString.split(SEPARATOR), segment = _a[0], parts = _a.slice(1);
    var partSegments = parts.map(function (part) {
        return /^\[.*\]$/.test(part) ? part.replace(/\[|\]/g, '').split(ITEM_SEPARATOR) : part;
    });
    var optionIds = pickOptionsId(partSegments.map(function (path) {
        return Array.isArray(path)
            ? path.map(function (p) {
                return options.find(function (option) { return sanitizeLabel(option.label) === decodeURIComponent(p); });
            })
            : options.find(function (option) { return sanitizeLabel(option.label) === decodeURIComponent(path); });
    }));
    if (!rootOptionIds.includes(optionIds[0])) {
        return null;
    }
    return [segment, optionIds];
});
var getHasUnsavedChanges = function (state) {
    var highwayState = (0, exports.getHighwayState)(state);
    var oriState = highwayState.oriState;
    if (!oriState || !oriState.roots) {
        return true;
    }
    return (highwayState.optionValues !== oriState.optionValues ||
        highwayState.roots.length !== oriState.roots.length ||
        highwayState.roots.some(function (root, index) {
            var oriRoot = oriState.roots[index];
            return (root.options.personal !== oriRoot.options.personal ||
                root.options.biz !== oriRoot.options.biz ||
                root.options.agriculture !== oriRoot.options.agriculture);
        }));
};
exports.getHasUnsavedChanges = getHasUnsavedChanges;
/**
 * compute the data to be provided to claim form for display and tracking.
 */
var computeHighwaySelectionResults = function (selectedId, optionMap, getLabel) {
    var result = [
        {
            title: getLabel('whatHappened'),
            displayValue: '',
        },
    ];
    for (var _i = 0, selectedId_1 = selectedId; _i < selectedId_1.length; _i++) {
        var idOrIds = selectedId_1[_i];
        if (Array.isArray(idOrIds)) {
            result[result.length - 1].displayValue = idOrIds.map(function (id) { return optionMap[id].label; });
            result[result.length - 1].title = getLabel('chooseWhatIsDamaged');
        }
        else {
            var option = optionMap[idOrIds];
            result[result.length - 1].displayValue = option.label;
            if (option.isContainer) {
                result.push({
                    title: option.sections[0].childrenLabel,
                    displayValue: '',
                });
            }
        }
    }
    return result;
};
exports.computeHighwaySelectionResults = computeHighwaySelectionResults;
