"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.saveConfig = exports.loadConfig = void 0;
var claim_config_1 = require("../services/claim-config");
var highway_slice_1 = require("./highway-slice");
var category_slice_1 = require("./category-slice");
var recipient_slice_1 = require("./recipient-slice");
var email_slice_1 = require("./email-slice");
var loadConfig = function (request) {
    return function (dispatch) {
        return (0, claim_config_1.getConfig)(request).then(function (stored) {
            if (stored) {
                dispatch(highway_slice_1.highwayActions.loadConfig(stored.highway));
                dispatch(category_slice_1.categoryActions.loadConfig(stored.category));
                dispatch(recipient_slice_1.recipientActions.loadConfig(stored.recipient));
                dispatch(email_slice_1.emailActions.loadConfig(stored.email));
            }
        });
    };
};
exports.loadConfig = loadConfig;
var saveConfig = function () { return function (dispatch, getState) {
    return (0, claim_config_1.postConfig)(getState()).then(function (stored) {
        dispatch(highway_slice_1.highwayActions.loadConfig(stored.highway));
        dispatch(category_slice_1.categoryActions.loadConfig(stored.category));
        dispatch(recipient_slice_1.recipientActions.loadConfig(stored.recipient));
        dispatch(email_slice_1.emailActions.loadConfig(stored.email));
    });
}; };
exports.saveConfig = saveConfig;
