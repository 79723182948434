"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.configListActions = exports.configListReducer = exports.initialState = void 0;
/**
 * @module This is not used in redux store; it is used as component state only.
 */
var toolkit_1 = require("@reduxjs/toolkit");
exports.initialState = {
    showDialog: false,
    sectionIndex: undefined,
    optionId: undefined,
};
var stateSlice = (0, toolkit_1.createSlice)({
    name: 'configList',
    initialState: exports.initialState,
    reducers: {
        showDialog: function (state, _a) {
            var _b = _a.payload, payload = _b === void 0 ? {} : _b;
            state.showDialog = true;
            state.sectionIndex = payload.sectionIndex;
        },
        showEditDialog: function (state, _a) {
            var payload = _a.payload;
            state.showDialog = true;
            state.optionId = payload.optionId;
        },
        closeDialog: function (state) {
            state.showDialog = false;
            state.sectionIndex = undefined;
            state.optionId = undefined;
        },
    },
});
exports.configListReducer = stateSlice.reducer;
exports.configListActions = stateSlice.actions;
