"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailList = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var react_ui_1 = require("@flos/react-ui");
var React = __importStar(require("react"));
var delete_confirmation_1 = require("./delete-confirmation");
var email_config_form_1 = require("./email-config-form");
var record_list_1 = require("./record-list");
var EmailList = function (props) {
    var _a = React.useReducer(emailListReducer, initialState), state = _a[0], dispatch = _a[1];
    var onDismissDialog = function () { return dispatch(actions.dismiss()); };
    var allowed = React.useMemo(function () {
        return state.mode === 'delete'
            ? !props.options.some(function (option) { return option.emailId === state.focused.id; })
            : true;
    }, [props.options, state.focused, state.mode]);
    return (React.createElement(React.Fragment, null,
        React.createElement(record_list_1.RecordList, null, props.emails.map(function (email) { return (React.createElement(record_list_1.RecordItem, { title: React.createElement(React.Fragment, null,
                React.createElement("b", null, email.uid),
                " ",
                email.title), subTitle: "".concat(email.header, " ").concat(email.intro), onEdit: function () { return dispatch(actions.editEmail(email)); }, onRemove: function () { return dispatch(actions.deleteEmail(email)); }, "data-testid": "email-".concat(email.uid), key: email.id })); })),
        React.createElement(react_ui_1.Modal, { isOpen: state.mode !== 'close', onDismiss: onDismissDialog },
            state.mode === 'edit' && (React.createElement(email_config_form_1.EmailConfigForm, { currentEmail: state.focused, allEmails: props.emails, onSubmit: function (updatedEmail) {
                    props.onUpdateEmail(updatedEmail);
                    onDismissDialog();
                }, onCancel: onDismissDialog })),
            state.mode === 'delete' &&
                (allowed ? (React.createElement(delete_confirmation_1.DeleteConfirmation, { subject: React.createElement("span", null,
                        state.focused.uid,
                        " ",
                        state.focused.title), onConfirm: function () {
                        onDismissDialog();
                        props.onRemoveEmail(state.focused.id);
                    }, onCancel: onDismissDialog })) : (React.createElement("div", null,
                    React.createElement("div", { "data-testid": "dialog-msg" },
                        React.createElement("p", null, "Deletion of this email is not allowed as it is used by some highway option(s) currently."),
                        React.createElement("p", null, "Remove the email from the option(s) before delete this email.")),
                    React.createElement(react_ui_1.ButtonGroup, null,
                        React.createElement(react_ui_1.Button, { onClick: onDismissDialog }, "OK"))))))));
};
exports.EmailList = EmailList;
var initialState = {
    focused: null,
    mode: 'close',
};
var emailListSlice = (0, toolkit_1.createSlice)({
    name: 'emailList',
    initialState: initialState,
    reducers: {
        editEmail: function (_, _a) {
            var payload = _a.payload;
            return ({
                mode: 'edit',
                focused: payload,
            });
        },
        deleteEmail: function (_, _a) {
            var payload = _a.payload;
            return ({
                mode: 'delete',
                focused: payload,
            });
        },
        dismiss: function () {
            return ({
                focused: null,
                mode: 'close',
            });
        },
    },
});
var emailListReducer = emailListSlice.reducer;
var actions = emailListSlice.actions;
