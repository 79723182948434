"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logActions = exports.logReducer = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var DEFAULT_STATE = { modifiedChanges: {} };
var logSlice = (0, toolkit_1.createSlice)({
    name: 'log',
    initialState: DEFAULT_STATE,
    reducers: {
        addLog: function (state, _a) {
            var payload = _a.payload;
            state.modifiedChanges = payload;
        },
        loadConfig: function () { },
    },
});
exports.logReducer = logSlice.reducer;
exports.logActions = logSlice.actions;
