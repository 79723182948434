"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.categoryActions = exports.categoryReducer = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var app_constant_1 = require("../app/form-builder/app-constant");
var DEFAULT_STATE = {
    categoryIds: [],
    categoryById: {},
    lossTypeById: {},
};
var categorySlice = (0, toolkit_1.createSlice)({
    name: 'category',
    initialState: DEFAULT_STATE,
    reducers: {
        addCategory: function (state, _a) {
            var payload = _a.payload;
            state.categoryIds.push(payload.id);
            state.categoryById[payload.id] = payload;
        },
        updateCategory: function (state, _a) {
            var payload = _a.payload;
            state.categoryById[payload.id] = payload;
        },
        removeCategory: function (state, _a) {
            var payload = _a.payload;
            Object.values(state.lossTypeById).forEach(function (lossType) {
                if (lossType.categoryId === payload) {
                    delete state.lossTypeById[lossType.id];
                }
            });
            delete state.categoryById[payload];
            state.categoryIds.splice(state.categoryIds.indexOf(payload), 1);
        },
        addLossType: function (state, _a) {
            var payload = _a.payload;
            state.lossTypeById[payload.id] = payload;
        },
        updateLossType: function (state, _a) {
            var payload = _a.payload;
            state.lossTypeById[payload.id] = payload;
        },
        removeLossType: function (state, action) {
            delete state.lossTypeById[action.payload];
        },
        loadConfig: function (_, action) {
            app_constant_1.AppConstant.STOREVAL = __assign(__assign({}, app_constant_1.AppConstant.STOREVAL), { category: action.payload });
            return __assign(__assign({}, action.payload), { oriState: action.payload });
        },
    },
});
exports.categoryReducer = categorySlice.reducer;
exports.categoryActions = categorySlice.actions;
