"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getConfig = exports.postConfig = exports.logChanges = exports.setPostConfigHandler = void 0;
var react_ui_1 = require("@flos/react-ui");
var fetch_json_1 = require("../fetch-json");
var validate_session_1 = require("./validate-session");
var app_constant_1 = require("../app/form-builder/app-constant");
var json_diff_1 = require("json-diff");
var STORAGE_KEY = 'claimHighway';
var REQUEST;
var hasBeenEditingSince = '';
var modifiedChanges;
var setPostConfigHandler = function (request) {
    REQUEST = request;
};
exports.setPostConfigHandler = setPostConfigHandler;
/**
 *
 * Function to log the changes based on the difference in json
 */
var logChanges = function (storeState) {
    var _a, _b, _c, _d, _e;
    var getConfig = getPostSaveConfig(storeState);
    modifiedChanges = {};
    var initialStoreVal = JSON.stringify(app_constant_1.AppConstant.STOREVAL);
    var changedStoreVal = JSON.stringify(getConfig);
    var diffJson = (0, json_diff_1.diff)(JSON.parse(initialStoreVal), JSON.parse(changedStoreVal));
    ((_a = diffJson.highway) === null || _a === void 0 ? void 0 : _a.optionValues)
        ? (modifiedChanges.highway = Object.entries(diffJson.highway.optionValues))
        : Array();
    ((_b = diffJson.category) === null || _b === void 0 ? void 0 : _b.categoryById)
        ? (modifiedChanges.category = Object.entries(diffJson.category.categoryById))
        : Array();
    ((_c = diffJson.category) === null || _c === void 0 ? void 0 : _c.lossTypeById)
        ? (modifiedChanges.lossTypeById = Object.entries(diffJson.category.lossTypeById))
        : Array();
    ((_d = diffJson.email) === null || _d === void 0 ? void 0 : _d.emailById)
        ? (modifiedChanges.email = Object.entries(diffJson.email.emailById))
        : Array();
    ((_e = diffJson.recipient) === null || _e === void 0 ? void 0 : _e.recipientById)
        ? (modifiedChanges.recipient = Object.entries(diffJson.recipient.recipientById))
        : Array();
    return modifiedChanges;
};
exports.logChanges = logChanges;
var getPostSaveConfig = function (_a) {
    var highway = _a.highway, category = _a.category, recipient = _a.recipient, email = _a.email, loggedChanges = _a.loggedChanges;
    var savedConfig = {
        highway: (0, react_ui_1.omit)(highway, ['oriState']),
        category: (0, react_ui_1.omit)(category, ['oriState']),
        recipient: (0, react_ui_1.omit)(recipient, ['oriState']),
        email: (0, react_ui_1.omit)(email, ['oriState']),
        loggedChanges: (0, react_ui_1.omit)(loggedChanges, []),
    };
    return savedConfig;
};
/**
 * Function to call the post service for updataing the highway.json in bucket
 */
var postConfig = function (_a) {
    var highway = _a.highway, category = _a.category, recipient = _a.recipient, email = _a.email, loggedChanges = _a.loggedChanges;
    return new Promise(function (fulfill, reject) {
        try {
            var getSavedConfig_1 = getPostSaveConfig({
                highway: highway,
                category: category,
                recipient: recipient,
                email: email,
                loggedChanges: loggedChanges,
            });
            var data = JSON.stringify(getSavedConfig_1);
            localStorage.setItem(STORAGE_KEY, data);
            var request = __assign({}, REQUEST);
            // Object.assign(request.headers, REQUEST.headers);
            request.headers = request.headers || {};
            request.headers['x-if-unmodified-since'] = hasBeenEditingSince;
            var getIdToken = localStorage.getItem('idToken');
            request.headers['x-auth-cognito'] = "Bearer ".concat(getIdToken);
            var url = REQUEST.url;
            var params = {
                retryDelays: [],
                method: 'POST',
                body: data,
                headers: REQUEST.headers,
            };
            (0, fetch_json_1.fetchJson)(url, params)
                .then(function (_response) {
                // Temporarily defer edit time by 2 seconds to compensate for time misalignment.
                // Better solution is to get the last-modified datetime from S3
                setTimeout(function () {
                    hasBeenEditingSince = currentTime();
                }, 2000);
                if (_response.notSaved) {
                    alert('Your changes are not saved as someone else saved the highway since your last page reload. Please refresh the page.');
                }
                app_constant_1.AppConstant.STOREVAL = getSavedConfig_1;
                fulfill(getSavedConfig_1);
            })
                .catch(function (e) {
                (0, validate_session_1.logoutClearStorage)();
                reject(e);
            });
        }
        catch (e) {
            alert(exceptionMessage(e));
            reject(e);
        }
    });
};
exports.postConfig = postConfig;
/**
 * Get the config
 */
var getConfig = function (request) {
    return new Promise(function (fullfill, reject) { return __awaiter(void 0, void 0, void 0, function () {
        var url, params;
        return __generator(this, function (_a) {
            try {
                hasBeenEditingSince = currentTime();
                localStorage.getItem(STORAGE_KEY);
                url = request.url;
                params = {
                    method: 'GET',
                    headers: request.headers,
                };
                (0, fetch_json_1.fetchJson)(url, params)
                    .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        fullfill(response);
                        return [2 /*return*/];
                    });
                }); })
                    .catch(function (e) {
                    reject(e);
                });
            }
            catch (e) {
                reject(e);
            }
            return [2 /*return*/];
        });
    }); });
};
exports.getConfig = getConfig;
var currentTime = function () { return new Date().toISOString(); };
var exceptionMessage = function (e) {
    return typeof e == 'string' ? e : typeof e === 'object' && e.message ? e.message : JSON.stringify(e);
};
