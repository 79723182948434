"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HighwayConfigOptionLink = void 0;
var react_ui_1 = require("@flos/react-ui");
var React = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var highway_selectors_1 = require("../store/highway.selectors");
var highway_config_context_1 = require("./highway-config-context");
var HighwayConfigOptionLink = function (props) {
    var segment = React.useContext(highway_config_context_1.HighwayConfigContext);
    var path = (0, react_redux_1.useSelector)(function (state) { return (0, highway_selectors_1.generatePathString)(state, props.optionId, segment); });
    var _a = (0, react_ui_1.useTransientState)(false), copied = _a[0], setCopied = _a[1];
    var copyLink = function () {
        (0, react_ui_1.copyToClipboard)(path);
        setCopied(true);
    };
    return (React.createElement(react_ui_1.Modal, { isOpen: true, onDismiss: props.onDismiss },
        React.createElement("p", null, "Param to fast-forward highway to this option is:"),
        React.createElement("pre", { "data-testid": "link" }, path),
        React.createElement(react_ui_1.ButtonGroup, null,
            React.createElement(react_ui_1.Button, { onClick: copyLink, autoFocus: true }, copied ? 'Copied!' : 'Copy'),
            React.createElement(react_ui_1.Button, { onClick: props.onDismiss }, "Close"))));
};
exports.HighwayConfigOptionLink = HighwayConfigOptionLink;
