"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.emailActions = exports.emailReducer = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var app_constant_1 = require("../app/form-builder/app-constant");
var DEFAULT_STATE = {
    emailIds: [],
    emailById: {},
};
var emailSlice = (0, toolkit_1.createSlice)({
    name: 'email',
    initialState: DEFAULT_STATE,
    reducers: {
        addEmail: function (state, _a) {
            var payload = _a.payload;
            state.emailIds.push(payload.id);
            state.emailById[payload.id] = payload;
        },
        updateEmail: function (state, _a) {
            var payload = _a.payload;
            state.emailById[payload.id] = payload;
        },
        removeEmail: function (state, _a) {
            var payload = _a.payload;
            delete state.emailById[payload];
            state.emailIds.splice(state.emailIds.indexOf(payload), 1);
        },
        //AppConstant.STOREVAL.category = action.payload;
        loadConfig: function (state, action) {
            app_constant_1.AppConstant.STOREVAL = __assign(__assign({}, app_constant_1.AppConstant.STOREVAL), { email: action.payload });
            return action.payload
                ? __assign(__assign({}, action.payload), { oriState: action.payload }) : state;
        },
    },
});
exports.emailReducer = emailSlice.reducer;
exports.emailActions = emailSlice.actions;
