"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.recipientActions = exports.recipientReducer = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var app_constant_1 = require("../app/form-builder/app-constant");
var DEFAULT_STATE = {
    recipientIds: [],
    recipientById: {},
};
var recipientSlice = (0, toolkit_1.createSlice)({
    name: 'recipient',
    initialState: DEFAULT_STATE,
    reducers: {
        addRecipient: function (state, _a) {
            var payload = _a.payload;
            state.recipientIds.push(payload.id);
            state.recipientById[payload.id] = payload;
        },
        updateRecipient: function (state, _a) {
            var payload = _a.payload;
            state.recipientById[payload.id] = payload;
        },
        removeRecipient: function (state, _a) {
            var payload = _a.payload;
            delete state.recipientById[payload];
            state.recipientIds.splice(state.recipientIds.indexOf(payload), 1);
        },
        loadConfig: function (_, action) {
            app_constant_1.AppConstant.STOREVAL = __assign(__assign({}, app_constant_1.AppConstant.STOREVAL), { recipient: action.payload });
            return __assign(__assign({}, action.payload), { oriState: action.payload });
        },
    },
});
exports.recipientReducer = recipientSlice.reducer;
exports.recipientActions = recipientSlice.actions;
