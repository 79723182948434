"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFormioFormOptions = void 0;
var reselect_1 = require("reselect");
var highway_selectors_1 = require("./highway.selectors");
var selectFormioState = function (state) { return state.formio; };
exports.getFormioFormOptions = (0, reselect_1.createSelector)(selectFormioState, highway_selectors_1.getHighwayResultOptions, function (formioState, options) {
    return formioState.forms.map(function (form) {
        var optionCount = options.filter(function (option) { return option.targetPath === form.path; }).length;
        var _a = formatPath(form.path), path = _a[0], segment = _a[1];
        var formPath = path + ' ' + segment;
        return {
            id: form.path,
            value: "".concat(form.title, " (").concat(formPath, ")"),
            label: "".concat(form.title, " (").concat(formPath, ")").concat(optionCount ? " - selected by ".concat(optionCount, " option(s).") : ''),
            associatedOptionCount: optionCount,
        };
    });
});
var formatPath = function (path) {
    var open = path.lastIndexOf('open');
    var index = open >= 0 ? open : path.lastIndexOf('mit');
    if (index < 0) {
        return [path, ''];
    }
    else {
        return [path.substring(0, index), path.substring(index)];
    }
};
