"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.highwayActions = exports.highwayReducer = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var react_ui_1 = require("@flos/react-ui");
var lib_1 = require("../lib");
var app_constant_1 = require("../app/form-builder/app-constant");
var DEFAULT_STATE = {
    roots: [
        {
            brand: 'TD',
            site: 'open',
            options: {
                personal: [],
                biz: [],
                agriculture: [],
            },
        },
        {
            brand: 'TD',
            site: 'closed',
            options: {
                personal: [],
                biz: [],
                agriculture: [],
            },
        },
        {
            brand: 'CO',
            site: 'open',
            options: {
                personal: [],
                biz: [],
                agriculture: [],
            },
        },
        {
            brand: 'CO',
            site: 'closed',
            options: {
                personal: [],
                biz: [],
                agriculture: [],
            },
        },
    ],
    optionValues: {},
    oriState: undefined,
};
var highwaySlice = (0, toolkit_1.createSlice)({
    initialState: DEFAULT_STATE,
    reducers: {
        addRootOption: function (state, _a) {
            var payload = _a.payload;
            var _b = payload.root, brand = _b[0], site = _b[1];
            state.roots.forEach(function (root) {
                if (root.brand === brand && root.site === site) {
                    root.options[payload.segment].push(payload.option.id);
                }
            });
            state.optionValues[payload.option.id] = payload.option;
        },
        deleteRootOption: function (state, _a) {
            var payload = _a.payload;
            var _b = payload.root, brand = _b[0], site = _b[1];
            state.roots.forEach(function (root) {
                if (root.brand === brand && root.site === site) {
                    root.options[payload.segment].splice(root.options[payload.segment].indexOf(payload.id), 1);
                }
            });
            delete state.optionValues[payload.id];
        },
        reorderRootOption: function (state, _a) {
            var payload = _a.payload;
            if (payload.source === payload.destination) {
                return;
            }
            var _b = payload.root, brand = _b[0], site = _b[1];
            state.roots.forEach(function (root) {
                if (root.brand === brand && root.site === site) {
                    root.options[payload.segment] = (0, lib_1.reorderArray)(root.options[payload.segment], payload.source, payload.destination);
                }
            });
        },
        addOption: function (state, _a) {
            var payload = _a.payload;
            var parentDetails = state.optionValues[payload.parentId];
            // default priority to be lower priority
            state.optionValues[payload.option.id] = parentDetails.allowMultipleChildren
                ? __assign(__assign({}, payload.option), { priority: Math.max.apply(Math, __spreadArray(__spreadArray([], (0, react_ui_1.concatAll)(parentDetails.sections.map(function (section) {
                        return section.childrenIds.map(function (childId) {
                            return state.optionValues[childId]
                                .priority;
                        });
                    })), false), [0], false)) + 1 })
                : payload.option;
            state.optionValues[payload.parentId].sections[payload.sectionIndex].childrenIds.push(payload.option.id);
        },
        reorderOption: function (state, _a) {
            var payload = _a.payload;
            if (payload.source === payload.destination) {
                return;
            }
            var parentId = payload.parentId;
            // YIJ - Previously only enabled for development
            // It should either always be used for all modes, or simply deleted
            //
            // if (!state.optionValues[parentId] || !state.optionValues[parentId].isContainer) {
            //     throw new Error(`reorderOption not possible if the option is NOT container`);
            // }
            state.optionValues[parentId].sections[payload.sectionIndex].childrenIds = (0, lib_1.reorderArray)(state.optionValues[parentId].sections[payload.sectionIndex].childrenIds, payload.source, payload.destination);
        },
        deleteOption: function (state, _a) {
            var payload = _a.payload;
            // assumption: an option cannot be removed unless all its children is removed
            // we may consider to support recursive delete if it is really needed
            delete state.optionValues[payload.id];
            state.optionValues[payload.parentId].sections[payload.sectionIndex].childrenIds.splice(state.optionValues[payload.parentId].sections[payload.sectionIndex].childrenIds.indexOf(payload.id), 1);
        },
        setOptionPrioriy: function (state, action) {
            state.optionValues[action.payload.id].priority =
                action.payload.priority;
        },
        setOption: function (state, action) {
            Object.assign(state.optionValues[action.payload.id], action.payload);
        },
        setOptionChildrenLabel: function (state, _a) {
            var payload = _a.payload;
            state.optionValues[payload.id].sections[payload.sectionIndex].childrenLabel = payload.label;
        },
        setOptionFaqUrl: function (state, _a) {
            var payload = _a.payload;
            state.optionValues[payload.id].faqUrl = payload.pathToFaq;
            state.optionValues[payload.id].sections.splice(1);
        },
        setOptionAllowMultipleChildren: function (state, _a) {
            var payload = _a.payload;
            state.optionValues[payload.id].allowMultipleChildren =
                payload.allowMultiple;
            state.optionValues[payload.id].sections.splice(1);
        },
        addOptionSection: function (state, action) {
            state.optionValues[action.payload.id].sections.push({
                childrenIds: [],
                childrenLabel: '',
            });
        },
        removeOptionSection: function (state, _a) {
            var payload = _a.payload;
            state.optionValues[payload.id].sections.splice(payload.sectionIndex, 1);
        },
        loadConfig: function (state, action) {
            app_constant_1.AppConstant.STOREVAL = __assign(__assign({}, app_constant_1.AppConstant.STOREVAL), { highway: action.payload });
            state.optionValues = action.payload.optionValues || {};
            var loadedRoots = action.payload.roots || [];
            loadedRoots.forEach(function (root) {
                var currentIndex = state.roots.findIndex(function (currentRoot) {
                    return currentRoot.brand === root.brand && currentRoot.site === root.site;
                });
                // we restore roots gracefully so new roots will not wiped out by old roots
                if (currentIndex >= 0) {
                    state.roots[currentIndex] = root;
                }
                else {
                    state.roots.push(root);
                }
            });
            state.oriState = action.payload;
        },
    },
    name: 'highway',
});
exports.highwayReducer = highwaySlice.reducer;
exports.highwayActions = highwaySlice.actions;
