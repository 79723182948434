"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryConfig = void 0;
var react_ui_1 = require("@flos/react-ui");
var React = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var category_config_form_1 = require("../components/category-config-form");
var category_list_1 = require("../components/category-list");
var lib_1 = require("../lib");
var category_slice_1 = require("../store/category-slice");
var category_selector_1 = require("../store/category.selector");
var highway_selectors_1 = require("../store/highway.selectors");
var CategoryConfigView = function (props) {
    var getLabel = (0, lib_1.useLabel)();
    var _a = React.useState(false), showAddCategoryForm = _a[0], setShowAddCategoryForm = _a[1];
    var addCategoryBtnRef = React.useRef(null);
    var closeDialog = function () {
        setShowAddCategoryForm(false);
        if (addCategoryBtnRef.current) {
            addCategoryBtnRef.current.focus();
        }
    };
    return (React.createElement(react_ui_1.Section, null,
        React.createElement(react_ui_1.SectionHeader, null, getLabel('Claim Category')),
        React.createElement(react_ui_1.ButtonGroup, null,
            React.createElement(react_ui_1.Button, { onClick: function () { return setShowAddCategoryForm(true); }, minWidth: "wide", ref: addCategoryBtnRef }, getLabel('Add Category'))),
        React.createElement(react_ui_1.Modal, { isOpen: showAddCategoryForm, onDismiss: closeDialog }, showAddCategoryForm && (React.createElement(category_config_form_1.CategoryConfigForm, { onSubmit: function (newCategory) {
                props.addCategory(newCategory);
                closeDialog();
            }, onCancel: closeDialog }))),
        React.createElement(category_list_1.CategoryList, { categories: props.categories, options: props.options, onUpdateCategory: props.updateCategory, onRemoveCategory: props.removeCategory, onAddLossType: props.addLossType, onUpdateLossType: props.updateLossType, onRemoveLossType: props.removeLossType })));
};
var connector = (0, react_redux_1.connect)(function (state) {
    return {
        categories: (0, category_selector_1.getCategories)(state),
        options: (0, highway_selectors_1.getHighwayResultOptions)(state),
    };
}, category_slice_1.categoryActions);
exports.CategoryConfig = connector(CategoryConfigView);
