"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HighwayConfigRoot = void 0;
var react_ui_1 = require("@flos/react-ui");
var React = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var lib_1 = require("../lib");
var highway_slice_1 = require("../store/highway-slice");
var highway_selectors_1 = require("../store/highway.selectors");
var highway_config_context_1 = require("./highway-config-context");
var highway_config_list_1 = require("./highway-config-list");
var highway_config_option_form_1 = require("./highway-config-option-form");
var highway_config_option_list_1 = require("./highway-config-option-list");
var highway_config_slice_1 = require("./highway-config-slice");
var HighwayConfigRootView = function (props) {
    var getLabel = (0, lib_1.useLabel)();
    var _a = React.useReducer(highway_config_slice_1.configListReducer, highway_config_slice_1.initialState), state = _a[0], dispatch = _a[1];
    var _b = React.useState(''), activeOptionId = _b[0], setActiveOptionId = _b[1];
    var _c = React.useState('personal'), segment = _c[0], setSegment = _c[1];
    var options;
    switch (segment) {
        case 'personal':
            options = props.personalOptions;
            break;
        case 'biz':
            options = props.bizOptions;
            break;
        case 'agriculture':
            options = props.agriOptions;
            break;
        default:
            throw Error('Unknown segment: ' + segment);
    }
    React.useEffect(function () {
        if (activeOptionId && options && !options.some(function (opt) { return opt.id === activeOptionId; })) {
            setActiveOptionId('');
        }
    }, [options, activeOptionId]);
    return (React.createElement(highway_config_context_1.HighwayConfigContext.Provider, { value: segment },
        React.createElement(react_ui_1.Section, null,
            React.createElement(react_ui_1.SelectableBoxField, { label: "Segment", value: segment, onChangeValue: function (val) { return setSegment(val); }, options: [
                    { value: 'personal', label: getLabel('personal') },
                    { value: 'biz', label: getLabel('biz') },
                    { value: 'agriculture', label: getLabel('agriculture') },
                ], multiple: false }),
            React.createElement(react_ui_1.ButtonGroup, null,
                React.createElement(react_ui_1.Button, { onClick: function () { return dispatch(highway_config_slice_1.configListActions.showDialog()); }, minWidth: "wide" }, "Add Option")),
            React.createElement(highway_config_option_list_1.HighwayConfigOptionList, { options: options, activeOptionId: activeOptionId, setActiveOptionId: setActiveOptionId, allowMultiple: false, onSortEnd: function (order) { return props.reorder(order.oldIndex, order.newIndex, segment); }, onEditOption: function (optionId) {
                    return dispatch(highway_config_slice_1.configListActions.showEditDialog({ optionId: optionId }));
                }, onDelete: function (optionId) {
                    props.deleteOption(optionId, segment);
                }, axis: "xy" })),
        activeOptionId && React.createElement(highway_config_list_1.HighwayConfigList, { parentId: activeOptionId, depth: 1 }),
        React.createElement(react_ui_1.Modal, { isOpen: state.showDialog, onDismiss: function () { return dispatch(highway_config_slice_1.configListActions.closeDialog()); } }, state.showDialog && (React.createElement(highway_config_option_form_1.HighwayConfigOptionForm, { onSubmit: function (values) {
                if (state.optionId) {
                    props.updateOption(values);
                }
                else {
                    props.addOption(values, segment);
                }
                dispatch(highway_config_slice_1.configListActions.closeDialog());
            }, optionId: state.optionId, onCancel: function () { return dispatch(highway_config_slice_1.configListActions.closeDialog()); }, isMultipleOption: false })))));
};
var connector = (0, react_redux_1.connect)(function (state, ownProps) {
    var rootDetails = [ownProps.brand, ownProps.site];
    return {
        personalOptions: (0, highway_selectors_1.getPersonalOptions)(state, rootDetails),
        bizOptions: (0, highway_selectors_1.getRootBizOptions)(state, rootDetails),
        agriOptions: (0, highway_selectors_1.getRootAgriOptions)(state, rootDetails),
    };
}, function (dispatch, ownProps) {
    var root = [ownProps.brand, ownProps.site];
    return {
        addOption: function (option, segment) {
            return dispatch(highway_slice_1.highwayActions.addRootOption({ option: option, segment: segment, root: root }));
        },
        updateOption: function (values) { return dispatch(highway_slice_1.highwayActions.setOption(values)); },
        reorder: function (oldIndex, newIndex, segment) {
            dispatch(highway_slice_1.highwayActions.reorderRootOption({
                segment: segment,
                root: root,
                source: oldIndex,
                destination: newIndex,
            }));
        },
        deleteOption: function (optionId, segment) {
            return dispatch(highway_slice_1.highwayActions.deleteRootOption({
                id: optionId,
                root: root,
                segment: segment,
            }));
        },
    };
});
exports.HighwayConfigRoot = connector(HighwayConfigRootView);
