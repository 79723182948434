"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHasRecipientChange = exports.getRecipientOptions = exports.getRecipients = void 0;
var reselect_1 = require("reselect");
var getRecipientState = function (state) { return state.recipient; };
var getRecipients = function (state) {
    return Object.values(getRecipientState(state).recipientById);
};
exports.getRecipients = getRecipients;
var getRecipientOptions = function (state, selectedRecipientIds) {
    return (0, exports.getRecipients)(state).map(function (recipient) {
        var value = "".concat(recipient.code, ": ").concat(recipient.name);
        return {
            id: recipient.id,
            value: value,
            label: "".concat(value),
            disabled: selectedRecipientIds.includes(recipient.id),
        };
    });
};
exports.getRecipientOptions = getRecipientOptions;
exports.getHasRecipientChange = (0, reselect_1.createSelector)(getRecipientState, function (_a) {
    var recipientById = _a.recipientById, oriState = _a.oriState;
    if (!oriState) {
        return true;
    }
    return oriState.recipientById !== recipientById;
});
