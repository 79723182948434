"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formioReducer = exports.formioActions = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var DEFAULT_STATE = {
    forms: [],
};
var formioSlice = (0, toolkit_1.createSlice)({
    name: 'formio',
    initialState: DEFAULT_STATE,
    reducers: {
        setForms: function (state, _a) {
            var payload = _a.payload;
            state.forms = payload;
        },
    },
});
exports.formioActions = formioSlice.actions;
exports.formioReducer = formioSlice.reducer;
